import React from "react";
import { Column } from "../layout/Layout";
import {
  TextArea,
  ImageCover,
  SVG,
  StyledLink,
} from "../styles/Project.styles";
import { useParams } from "react-router-dom";
import data from "../../data/projects";

export const Project = () => {
  const params = useParams();
  const project = data.filter((project) => project.path === params.name);
  
  return (
    <Column
      style={{
        justifyContent: "center",
        alignItems: "center",
        flex: "0 1 auto",
        marginBottom: "10px",
      }}
    > 
      <ImageCover>
        <img src={project[0].img} alt="some alt" />
        <figcaption>{project[0].caption}</figcaption>
      </ImageCover>
      <TextArea style={{ margin: "0 4%" }}>
        <p>
          {project[0].description}
          <br />
          <br />
          {project[0].work}
          <br />
          <br />
          {project[0].role}          
        </p>
        <p>Tech: {project[0].tech}</p>
        <StyledLink
          href={project[0].links[0].live}
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit Demo Site
          <div>
            <SVG>
              <path
                d="M6.188 4.833c.113-.118.298-.296.553-.533l.274-.253c-.392.009-.775.014-1.149.014H.281V2.939h5.585c.365 0 .748.005 1.149.014a50.395 50.395 0 0 1-.841-.827L4.089 0h1.6l3.274 3.5L5.688 7h-1.6l2.1-2.167z"
                fillRule="nonzero"
              ></path>
            </SVG>
          </div>
        </StyledLink>
      </TextArea>
    </Column>
  );
};
