import styled from "styled-components";

export const TextArea = styled.section`
  font-size: 1.14em;
  letter-spacing: 0.8px;
`;

export const ImageCover = styled.figure`
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
`;

export const StyledLink = styled.a`
  display: inline-flex;
  height: 54px;
  border-radius: 27px;
  font-size: 0.875em;
  letter-spacing: 0.8px;
  align-items: center;
  cursor: pointer;
  padding: 0 32px;
  background: var(--button);
  transition: opacity .2s ease-in-out 0s;
  &:hover {
    background: #DADADA;
  }
`;

export const SVG = styled.svg`
  width: 9px;
  height: 7px;
  margin-left: 15px;
  position: relative;
  top: -1px;
  path {
    fill: #fff;
  }
`;
