import React from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "../src/components/styles/GlobalStyles";
import { TopNav } from "./components/layout/TopNav";
import { AppLayout } from "./components/pages/AppLayout";
import "normalize.css";

function App() {
  return (
    <>
      <GlobalStyles />
      <main>
        <div id="animation-div" />
        <BrowserRouter>
          <TopNav />
          <AppLayout />
        </BrowserRouter>
      </main>
    </>
  );
}

export default App;
