import styled from "styled-components";

export const Box = styled.div`
  display: flex;
`;

export const Column = styled.section`
  display: flex;
  flex-flow: column nowrap;
`;
