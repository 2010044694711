import React from "react";
import { Switch, Route } from "react-router-dom";
import { Project } from "../pages/Project";
import { About } from "../pages/About";

const Router = () => {
  return (
    <Switch>
      <Route path="/project/:name" component={Project} />
      <Route exact path="/about" component={About} />
    </Switch>
  );
};

export default Router;
