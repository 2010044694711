import React from "react";
import { Column } from "../layout/Layout";
import { TextArea } from "../styles/Project.styles";

export const About = () => {
  return (
    <Column style={{ flexBasis: "100%", alignItems: "center" }}>
      <div>
        <picture>
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
            media="(max-width: 1200px) 100vw, 1200px"
            srcSet="https://res.cloudinary.com/thecodehouse/image/upload/c_scale,h_760/v1602540929/personal-site/floral.jpg 600w,
            https://res.cloudinary.com/thecodehouse/image/upload/c_scale,h_540/v1602611536/personal-site/danny.jpg 1200w,"
            src="https://res.cloudinary.com/thecodehouse/image/upload/v1602611536/personal-site/danny.jpg"
            alt="some alt"
          />
        </picture>
      </div>
      <TextArea as="article" style={{ margin: "0 8%" }}>
        <p>
          Hi there{" "}
          <span role="img" aria-label="hand emoji">
            👋
          </span>{" "}
          My name is Danny Suarez. I'm a Software Enginner located in beautiful
          Portland, OR. I chose a career in software engineering because I
          wanted to be challenged, continue to learn, and have the opportunity
          to give back to the community (plus I really enjoy playing with code
          and logic).
        </p>
        <p>
          As much as I enjoy coding and solving interesting problems, I enjoy my
          down time just as much and try to fill it with activites that bring me
          joy.
        </p>
        <p>
          Some of those activites include watching Anime,{" "}
          <a
            href="https://www.instagram.com/coupleofpuzzlers/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: "#7f5af0" }}
          >
            puzzling,
          </a>{" "}
          virtual happy hours,{" "}
          <a
            href="http://bitbybitcoding.org/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: "#7f5af0" }}
          >
            volunteering,
          </a>{" "}
          and enjoying a wonderful life with my partner Kami and our dog Bezi.
        </p>
        <img
          alt="Danny and Kami wearing floral shirts"
          style={{ margin: "0 auto" }}
          src="https://res.cloudinary.com/thecodehouse/image/upload/c_scale,h_760/v1602540929/personal-site/floral.jpg"
        />
        <p>
          If you'd like to get in touch, email me :){" "}
          <a
            href="hello@dannycodes.io"
            style={{ textDecoration: "underline", color: "#7f5af0" }}
          >
            hello@dannycodes.io
          </a>{" "}
          You can also find me on{" "}
          <a
            href="https://www.linkedin.com/in/dannysuarezpdx/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: "#7f5af0" }}
          >
            LinkedIn
          </a>{" "}
          and{" "}
          <a
            href="https://github.com/DannySuarez"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: "#7f5af0" }}
          >
            GitHub
          </a>
        </p>
      </TextArea>
    </Column>
  );
};
