export default [
  {
    id: 1,
    name: "Casa De Caldos",
    img:
      "https://res.cloudinary.com/thecodehouse/image/upload/c_scale,w_1280/v1602010260/personal-site/casa.png",
    tech:
      "React, Styled-Componets, Firebase, Netlify Serverless Funtions, Stripe API",
    caption: "Menu items from Casa De Caldos",
    description:
      "Casa De Caldos has been serving up authentic Oaxacan food to the Woodburn, OR community since 2018. The family owned restaurant found success early on thanks in part to actively engaging with their clients on social media and of course having a menu worth raving about.",
    work:
      "I partnered up with Casa De Caldos when they saw the need to be online in order to better serve their customers. Working closely, we discovered that their app needed to be easy to use and mobile friendly.",
    links: [
      {
        github: "",
        live: "https://casadecaldos.netlify.app/",
      },
    ],
    path: "CasaDeCaldos",
  },
  {
    id: 2,
    name: "Happy Trees",
    img:
      "https://res.cloudinary.com/thecodehouse/image/upload/c_scale,h_573,q_98,w_1280/v1602011080/personal-site/happy-trees-small-copy_jyxbqs.png",
    tech: "React/Redux, Socket.IO, Node, Express, MongoDB ",
    caption: "A river being drawn on the Happy Trees game page",
    description:
      "Happy Trees is a full stack app built in a 1-week sprint by a team of 4. Together we built a game where players can play in real time.",
    work: "In Happy Trees, players take turns receiving a random prompt that they must try to draw on their device. While the “artist” is drawing, the other player can try and guess what is beign drawn and earn points.",
    role: "While working on Happy Trees I primarily worked on the front-end building components and hooking up Socket.IO.",
    links: [
      {
        github: "https://github.com/happy-trees",
        live: "https://happy-trees.netlify.com",
      },
    ],
    path: "HappyTrees",
  },
  {
    id: 3,
    name: "Simon",
    img:
      "https://res.cloudinary.com/thecodehouse/image/upload/c_scale,h_573,w_1280/v1602011717/personal-site/Simon.png",
    tech: "HTML, CSS, JavaScript",
    caption: "Game page of Simon",
    description: `A wonderfully relaxing memory/matching game built with 
    vanilla javascript, html and css.`,
    work:
      "3 Awesome developers came together to built this unique experiece. With our combined efforts and 1 month of experience with JavaScript, we utilized everything we larned and pushed ourselves to built something truly unique. Our app has wonderufl tones, unique color options, and of course, a leaderboard to keep track of your efforts as you take on this challenging game.",
    role: "While workign on the project I took on the responsibilty of implementing the leaderboard feature. Using the localStorage API of the browser, we are able to save and retrieve player information.",
    links: [
      {
        github: "https://github.com/simon-gang/simon-gang.github.io",
        live: "https://simon-gang.github.io/",
      },
    ],
    path: "Simon",
  },
  {
    id: 4,
    name: "Moddo",
    img:
      "https://res.cloudinary.com/thecodehouse/image/upload/c_scale,h_573,w_1280/v1602012189/personal-site/Moddo.png",
    tech:
      "HTML, CSS, JavaScript, Firebase, Random Advice Slip API, Lorem Picsum API, QUnit for testing",
    caption: "Moddo's home page",
    description: `A space to start your day, receive a daily random motto to inpire you,
    and keep track of your lists and tasks.`,
    work: "Moddo utilizes 2 APIs to generate a random picture and a random quote to get you in the perfect mood to start your day.",
    role: "While working on Moddo, I was responsible for integrating Google's Firebase service in order to save users tasks, and their favorite image/quote combination if they chose to favorite one.",
    links: [
      {
        github: "https://github.com/rubber-ducky-squad/Adviser",
        live: "https://moddo.netlify.com/",
      },
    ],
    path: "Moddo",
  },
];
