import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    --black: #0A0F18;
    --gold: #E59E00;
    --paragraph: #94a1b2;
    --button: #3B3F46;
    background: var(--black);
    color: var(--paragraph);
    font-family: "Monsterrat", sans-serif;
    font-size: 100%;
    box-sizing: border-box;
  }

  body {
    min-height: 100vh;
  }

  main {
    display: flex;
    flex-flow: row wrap;
  }

  a {
    text-decoration: none;
    color: var(--paragraph);
  }

  a:hover {
    cursor: pointer;
  }

  h2:hover {
    cursor: pointer;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .animation-div {
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 102;
    position: fixed;
    transform: translateX(-100%);
    background-color: var(--black);
    animation: slide 1s ease-in-out;
  }

  @keyframes slide {
    from {
      transform: translateX(20%);
    }

    to {
      transform: translateX(100%);

    }
  }
`;

export default GlobalStyles;
