import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ListItem } from "../styles/SideNav.styles";

export const TopNav = () => {
  let location = null;
  let pathname = null;
  if(useLocation().pathname === "/about") {
    location = "Home";
    pathname = "/";
  } else {
    location = "About";
    pathname = "/about";
  }

  return (
    <nav
      style={{
        justifyContent: "flex-end",
        marginRight: "4%",
        flexBasis: "100%",
        display: "flex",
      }}
    >
      <ul
        style={{
          listStyleType: "none",
          fontSize: "1.125em",
        }}
      >
        <ListItem style={{fontSize: "1.125em"}}>
          <Link to={{ pathname }}>{location}</Link>
        </ListItem>
      </ul>
    </nav>
  );
};
