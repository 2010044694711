import React from "react";
import { Link, useLocation } from "react-router-dom";
import { UnorderedList, ListItem } from "../styles/SideNav.styles";
import data from "../../data/projects";

export const SideNav = () => {
  const { pathname } = useLocation();
  if (pathname === "/about") return null;

  const animate = () => {
    const animationDiv = document.getElementById("animation-div");
    animationDiv.classList.add("animation-div");
    setTimeout(() => {
      animationDiv.classList.remove("animation-div");
    }, 1200);
  };

  return (
    <nav
      style={{
        flex: "1 0 auto",
        margin: "0 0 0 4%",
      }}
    >
      <UnorderedList>
        {data.map((project) => (
          <ListItem
            style={{
              fontWeight: "bold",
              margin: "0.83em 0px",
              fontSize: "1.5em",
              width: "max-content",
            }}
            key={project.id}
            onClick={() => animate()}
          >
            <Link
              className={
                pathname === `/project/${project.path}` ? "active" : null
              }
              to={`/project/${project.path}`}
            >
              {project.name}
            </Link>
          </ListItem>
        ))}
      </UnorderedList>
    </nav>
  );
};
