import React from "react";
import { Box } from "../layout/Layout";
import { SideNav } from "../layout/SideNav";
import Router from "../router/Router";

export const AppLayout = () => {
  return (
    <Box
      style={{
        flexFlow: "row nowrap",
        width: "100%",
      }}
    >
      <SideNav />
      <Router />
    </Box>
  );
};
