import React from "react";
import styled from "styled-components";
import { Column } from "../layout/Layout";

export const UnorderedList = ({ children }) => (
  <Column
    as="ul"
    style={{
      listStyleType: "none",
      padding: "0",
      margin: "0",
    }}
  >
    {children}
  </Column>
);

export const ListItem = styled.li`
  &:hover {
    cursor: pointer;
  }
  a {
    position: relative;
    ::after {
      content: "";
      position: absolute;
      background: var(--gold);
      height: 2px;
      left: 0px;
      width: 100%;
      bottom: -5px;
      transition: transform 0.3s ease 0s;
      transform: scale(0);
    }
    :hover::after {
      transform: scale(1);
    }
    &.active::after {
      transform: scale(1);
    }
  }
`;
